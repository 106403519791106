export default {
	name: 'form-string',
	props: ['value', 'label', 'required', 'items', 'color', 'item'],

	data() {
		return {
			errors: [],
			visible: true
		}
	},
	computed: {
		innerData: {
			get() {
				return this.value || {};
			},
			set(val) {
				this.$emit('input', val);
			}
		},

		flat() {
			return (this.item && this.item.flat != undefined) ? this.item.flat : true;
		}
	},
	methods: {
		validate(isValid) {
			this.$emit("valid", isValid);
		},

		fold() {
			this.visible = !this.visible;
		}
	}
};
